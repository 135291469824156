exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-board-page-tsx": () => import("./../../../src/templates/about-board-page.tsx" /* webpackChunkName: "component---src-templates-about-board-page-tsx" */),
  "component---src-templates-about-company-page-tsx": () => import("./../../../src/templates/about-company-page.tsx" /* webpackChunkName: "component---src-templates-about-company-page-tsx" */),
  "component---src-templates-about-join-us-page-tsx": () => import("./../../../src/templates/about-join-us-page.tsx" /* webpackChunkName: "component---src-templates-about-join-us-page-tsx" */),
  "component---src-templates-about-kestria-page-tsx": () => import("./../../../src/templates/about-kestria-page.tsx" /* webpackChunkName: "component---src-templates-about-kestria-page-tsx" */),
  "component---src-templates-about-mission-page-tsx": () => import("./../../../src/templates/about-mission-page.tsx" /* webpackChunkName: "component---src-templates-about-mission-page-tsx" */),
  "component---src-templates-about-personal-details-page-tsx": () => import("./../../../src/templates/about-personal-details-page.tsx" /* webpackChunkName: "component---src-templates-about-personal-details-page-tsx" */),
  "component---src-templates-about-press-post-page-tsx": () => import("./../../../src/templates/about-press-post-page.tsx" /* webpackChunkName: "component---src-templates-about-press-post-page-tsx" */),
  "component---src-templates-about-us-hub-page-tsx": () => import("./../../../src/templates/about-us-hub-page.tsx" /* webpackChunkName: "component---src-templates-about-us-hub-page-tsx" */),
  "component---src-templates-company-info-page-tsx": () => import("./../../../src/templates/company-info-page.tsx" /* webpackChunkName: "component---src-templates-company-info-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-department-details-page-tsx": () => import("./../../../src/templates/department-details-page.tsx" /* webpackChunkName: "component---src-templates-department-details-page-tsx" */),
  "component---src-templates-departments-page-tsx": () => import("./../../../src/templates/departments-page.tsx" /* webpackChunkName: "component---src-templates-departments-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-industries-details-page-tsx": () => import("./../../../src/templates/industries-details-page.tsx" /* webpackChunkName: "component---src-templates-industries-details-page-tsx" */),
  "component---src-templates-industries-hub-page-tsx": () => import("./../../../src/templates/industries-hub-page.tsx" /* webpackChunkName: "component---src-templates-industries-hub-page-tsx" */),
  "component---src-templates-job-offer-page-tsx": () => import("./../../../src/templates/job-offer-page.tsx" /* webpackChunkName: "component---src-templates-job-offer-page-tsx" */),
  "component---src-templates-job-offers-hub-tsx": () => import("./../../../src/templates/job-offers-hub.tsx" /* webpackChunkName: "component---src-templates-job-offers-hub-tsx" */),
  "component---src-templates-know-how-case-studies-tags-tsx": () => import("./../../../src/templates/know-how-case-studies-tags.tsx" /* webpackChunkName: "component---src-templates-know-how-case-studies-tags-tsx" */),
  "component---src-templates-know-how-case-studies-tsx": () => import("./../../../src/templates/know-how-case-studies.tsx" /* webpackChunkName: "component---src-templates-know-how-case-studies-tsx" */),
  "component---src-templates-know-how-hub-page-tsx": () => import("./../../../src/templates/know-how-hub-page.tsx" /* webpackChunkName: "component---src-templates-know-how-hub-page-tsx" */),
  "component---src-templates-know-how-post-page-tsx": () => import("./../../../src/templates/know-how-post-page.tsx" /* webpackChunkName: "component---src-templates-know-how-post-page-tsx" */),
  "component---src-templates-offers-consulting-details-page-tsx": () => import("./../../../src/templates/offers-consulting-details-page.tsx" /* webpackChunkName: "component---src-templates-offers-consulting-details-page-tsx" */),
  "component---src-templates-offers-employer-branding-page-tsx": () => import("./../../../src/templates/offers-employer-branding-page.tsx" /* webpackChunkName: "component---src-templates-offers-employer-branding-page-tsx" */),
  "component---src-templates-offers-executive-search-page-tsx": () => import("./../../../src/templates/offers-executive-search-page.tsx" /* webpackChunkName: "component---src-templates-offers-executive-search-page-tsx" */),
  "component---src-templates-offers-hr-consulting-page-tsx": () => import("./../../../src/templates/offers-hr-consulting-page.tsx" /* webpackChunkName: "component---src-templates-offers-hr-consulting-page-tsx" */),
  "component---src-templates-offers-it-contracting-page-tsx": () => import("./../../../src/templates/offers-it-contracting-page.tsx" /* webpackChunkName: "component---src-templates-offers-it-contracting-page-tsx" */),
  "component---src-templates-offers-media-service-page-tsx": () => import("./../../../src/templates/offers-media-service-page.tsx" /* webpackChunkName: "component---src-templates-offers-media-service-page-tsx" */),
  "component---src-templates-offers-payroll-page-tsx": () => import("./../../../src/templates/offers-payroll-page.tsx" /* webpackChunkName: "component---src-templates-offers-payroll-page-tsx" */),
  "component---src-templates-offers-payroll-software-page-tsx": () => import("./../../../src/templates/offers-payroll-software-page.tsx" /* webpackChunkName: "component---src-templates-offers-payroll-software-page-tsx" */),
  "component---src-templates-offers-payroll-staff-page-tsx": () => import("./../../../src/templates/offers-payroll-staff-page.tsx" /* webpackChunkName: "component---src-templates-offers-payroll-staff-page-tsx" */),
  "component---src-templates-offers-recruitment-details-page-tsx": () => import("./../../../src/templates/offers-recruitment-details-page.tsx" /* webpackChunkName: "component---src-templates-offers-recruitment-details-page-tsx" */),
  "component---src-templates-offers-recruitment-page-tsx": () => import("./../../../src/templates/offers-recruitment-page.tsx" /* webpackChunkName: "component---src-templates-offers-recruitment-page-tsx" */),
  "component---src-templates-privacy-policy-page-tsx": () => import("./../../../src/templates/privacy-policy-page.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-tsx" */),
  "component---src-templates-whistleblowers-en-page-tsx": () => import("./../../../src/templates/whistleblowers-en-page.tsx" /* webpackChunkName: "component---src-templates-whistleblowers-en-page-tsx" */),
  "component---src-templates-whistleblowers-page-tsx": () => import("./../../../src/templates/whistleblowers-page.tsx" /* webpackChunkName: "component---src-templates-whistleblowers-page-tsx" */),
  "component---src-templates-work-in-hrk-tsx": () => import("./../../../src/templates/work-in-hrk.tsx" /* webpackChunkName: "component---src-templates-work-in-hrk-tsx" */)
}

